import * as $ from "jquery";
import * as posterSrc from "../../../SharedResources/images/Doctors.jpg";
import * as logoSrc from "../../../SharedResources/images/R1_logo.svg";

$(() => {
    var isSubmitInProgress: boolean;

    if (!$(".logo-container a img").length) $(".logo-container a").html(`<img src="${logoSrc}" alt="R1"/>`);

    // Preserves hash on IE and Safari browsers.
    $(`[name="hash"]`).val(window.location.hash);

    // Update support contacts
    $(".js-attach-email").attr("href", "mail" + "to:PMHelp" + "@" + "r1rcm" + ".com?Subject=R1 Gateway");
    $(".js-attach-number").attr("href", "te" + "l:+" + "18662" + "174440");
    $(".js-attach-number-emergencies").attr("href", "te" + "l:+" + "18003" + "460747");

    function clean() {
        $(".result-message").html("");
        $("#UserEmail").val("");
        $("#UserEmail2").val("");
    }

    $(".js-forgot-password").on("click", () => {
        $(".page.active").removeClass("active");
        $(".forgot-password-page").addClass("active");
        clean();
    });

    $(".js-forgot-username").on("click", () => {
        $(".page.active").removeClass("active");
        $(".forgot-username").addClass("active");
        clean();
    });

    $(".js-back-to-login").on("click", () => {
        $(".page.active").removeClass("active");
        $(".login-page").addClass("active");
        clean();
    });

    $(".login-form").on("submit", (event) => {
        if (isSubmitInProgress) return true;

        if (!$("#UserName").val() || !$("#Password").val()) {
            $(".result-message").html("<span>Please enter your User ID and Password.</span>");
            event.preventDefault();
        } else {
            $(".js-submit-login").prop("disabled", true);
            isSubmitInProgress = true;
        }
    });

    $(".js-submit-password").on("click", () => {
        var requestUserEmail = $("#UserEmail").val();
        if (requestUserEmail) $(".js-submit-password").prop("disabled", true);
        requestLoginInfo("askPassword", requestUserEmail);
    });

    $(".js-submit-username").on("click", () => {
        var requestUserEmail = $("#UserEmail2").val();
        if (requestUserEmail) $(".js-submit-username").prop("disabled", true);
        requestLoginInfo("askUserID", requestUserEmail);
    });

    function requestLoginInfo(requestEmailType, requestUserEmail) {
        $(".result-message").html("");
        if (!requestUserEmail) {
            $(".result-message").html("<span>Please enter your Email.</span>");
        } else {
            $.ajax({
                type: "POST",
                url: "User/ForgottenPassword",
                data: JSON.stringify({ email: requestUserEmail, requestEmailType: requestEmailType }),
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                success: (data) => {
                    if (data.Errors !== null) {
                        if (data.Errors[0] === "The requested credential was sent to your email.") {
                            $(".page.active").removeClass("active");
                            $(".login-page").addClass("active");
                            $(".result-message").html(`<span>The requested credential was sent to your email.<br/>
                                If you do not find the email in your inbox, please check your Junk mail folder.</span>`);
                        } else {
                            $(".result-message").html(`<span>${data.Errors[0]}</span>`);
                        }
                    } else if (data.Successes !== null) {
                        alert(data.Successes[0]);
                    }
                },
                complete: () => {
                    $(".js-submit-password, .js-submit-username").prop("disabled", false);
                }
            });
        }
    }

    $("#Password2").on("focusout", () => {
        $("#Password").val($("#Password2").val());
    });

    $("#UserName2").on("focusout", () => {
        $("#UserName").val($("#UserName2").val());
    });

    $("#Password2").on("keypress", (event) => {
        $("#Password").val($("#Password2").val());
        var keycode = event.keyCode ? event.keyCode : event.which;
        if (keycode === 13 && !isSubmitInProgress) {
            $(".login-form").trigger("submit");
        }
    });
});

$(window).on("load", () => {
    // disable autocomplete hack
    $(`input[type="text"]`)
        .val("")
        .prop("disabled", false);
    $(`input[type="password"]`)
        .val("")
        .prop("disabled", false);

    // focus on UserID input when login page loads
    var isIe = /Trident\/|MSIE /.test(window.navigator.userAgent);
    if (!isIe) $("#UserName2").trigger("focus");

    // Load random background video on page load
    $(".video-container").html(`<div class="overlay"></div><video autoplay="" loop="" poster="${posterSrc}" muted=""></video>`);
    var sourceUrls = [
        "https://gateway.r1rcm.com/legacy/PSROnline2/MicroVideos/Login/Video1.mp4",
        "https://gateway.r1rcm.com/legacy/PSROnline2/MicroVideos/Login/Video2.mp4",
        "https://gateway.r1rcm.com/legacy/PSROnline2/MicroVideos/Login/Video3.mp4"
    ];
    $(document).ready(() => {
        if ($(".js-is-video-visible").is(":visible")) getSourceTag(0);
    });

    function getSourceTag(num) {
        if (num == 3) {
            num = 0;
        }
        var source = `<source src="${sourceUrls[num]}" type="video/mp4"/>`;
        $(".video-container")
            .html(`<div class="overlay"></div><video autoplay="autoplay" poster="${posterSrc}" muted="">${source}</video>`)
            .fadeIn(1000);
        $(".video-container video").bind("ended", () => {
            $(".video-container").fadeOut(1000, () => {
                getSourceTag(num + 1);
            });
        });
    }
});
